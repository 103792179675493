import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/city',
    },
    {
        path: '/index',
        name: 'index',
        component: () => import("../view/index"),
        meta: {
            title: '疫苗险',
            isBack: false,
            keepAlive: true,
        }
    },
    {
        path: '/rightsAndInterests',
        name: 'rightsAndInterests',
        component: () => import("../view/rightsAndInterests"),
        meta: {
            title: '保障权益',
        }
    },
    {
      path: '/insurePackage',
      name: 'insurePackage',
      component: () => import("../view/insurePackage/index"),
      meta: {
          title: '投保流程',
          isBack: false,
          keepAlive: true,
      }
    },
    {
        path: '/confirm',
        name: 'confirm',
        component: () => import("../view/insurePackage/confirmOrder"),
        meta: {
            title: '收银台',
        }
      },
    {
        path: '/material',
        name: 'material',
        component: () => import("../view/material"),
        meta: {
            title: '产品资料',
        }
    },
    {
        path: '/fileList',
        name: 'fileList',
        component: () => import("../view/fileList"),
        meta: {
            title: '产品资料',
        }
    },
    {
        path: '/pdf',
        name: 'pdf',
        component: () => import("../view/pdf"),
        meta: {
            title: '查看',
        }
    },
    {
        path: '/paySuccess',
        name: 'paySuccess',
        component: () => import("../view/paySuccess"),
        meta: {
            title: '投保成功',
        }
    },
    {
        path: '/payError',
        name: 'payError',
        component: () => import("../view/payError"),
        meta: {
            title: '支付失败',
        }
    },
    {
        path: '/insuranceFailure',
        name: 'insuranceFailure',
        component: () => import("../view/insuranceFailure"),
        meta: {
            title: '投保失败',
        }
    },
    {
        path: '/city',
        name: 'city',
        component: () => import("../view/city"),
        meta: {
            title: '选择疫苗接种点',
        }
    },
    {
        path: '/insuranceNotice',
        name: 'insuranceNotice',
        component: () => import("../view/insuranceNotice"),
        meta: {
            title: '投保须知',
        }
    },
    {
        path: '/exemptionClause',
        name: 'exemptionClause',
        component: () => import("../view/exemptionClause"),
        meta: {
            title: '责任免除条款',
        }
    },
    
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router