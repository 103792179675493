import Vue from 'vue';
import App from './App.vue';
import router from './router';
//清除浏览器默认样式
import '../node_modules/normalize.css/normalize.css'

//自适应rem
import '../node_modules/amfe-flexible/index.js'
import '../static/css/reset.css';

// 移动端控制台
// import Vconsole from 'vconsole'
// Vue.prototype.$Vconsole = new Vconsole()

// vant全局按需引入
import { Cascader, Tab, Tabs, Field, CellGroup, DatetimePicker, ActionSheet, Picker, Dialog, Popup, Toast, RadioGroup, Radio, Overlay } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Cascader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Overlay);

Vue.config.productionTip = false;

/*路由守卫*/
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
  window.scrollTo(0, 0)
})

new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')
