import axios from 'axios'
import { Toast } from 'vant'
// http request 拦截器
let requestCount = 0, responseCount = 0, toastLoading = null
axios.interceptors.request.use(
  config => {
    if (config.url.indexOf('sectionUpload') === -1) {
      toastLoading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 10000,
      });
      requestCount++
    }

    config.headers = {
      'Content-Type': 'application/json'
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.config.url.indexOf('sectionUpload') === -1) {
      responseCount++
      if (requestCount === responseCount) {
        toastLoading.clear()
      }
    }

    if (!response.data?.success) {
      Toast(response.data?.message)
    }
    return response.data;
  },
  error => {
    if (error.config.url.indexOf('sectionUpload') === -1) {
      responseCount++
      if (requestCount === responseCount) {
        toastLoading.clear()
      }
    }
    return Promise.reject(error)
  })


//get请求
export const GET = (url, param) => {
  if (param) {
    return axios.get(url, {
      params: param
    })
  } else {
    return axios.get(url)
  }
}
//post请求
export const POST = (url, param, config) => {
  if (config) {
    return axios.post(url, param, config)
  } else {
    return axios.post(url, param)
  }
}

export const PUT = (url, param) => {
  return axios.put(url, param)
}

export const DELETE = (url) => {
  return axios.delete(url)
}

